import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const HealthcarePage = () => (
  <Layout>
    <SEO title="Healthcare" description="" lang="en" meta={[]} />

    {/* Healthcare Section */}
    <section className="africa-healthcare focus-page">
      <h2 className="text-center mb-3">Healthcare &amp; Life Sciences</h2>
      <div className="section-img" />
    </section>
  </Layout>
)

export default HealthcarePage
